import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { MainMenu } from './MainMenu';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useStore } from 'app/context';
import { DatenStandList } from 'app/components/DatenStandList';

export const EnsureAdmin = observer(() => {
	const { session } = useStore();

	const currentUser = session.currentUser;
	const history = useHistory();

	if (currentUser && !currentUser.isAdmin) {
		history.push('/');
	}
	return <></>;
});

const AdminHomeContainer = observer((props: RouteComponentProps) => {
	const { session } = useStore();

	const currentUser = session.currentUser;

	return (
		<BaseContainer classModifier="admin-container" sideMenu={<MainMenu {...props} />} navbar={<div>Home</div>} actionControls={<></>}>
			<EnsureAdmin />
			{currentUser && (
				<div className="content pad-1rem">
					<div className="title">Admin</div>
					<p>Willkommen im Admin-Bereich</p>
					<div className="title">Aktuelle Datenstände</div>
					<DatenStandList />
				</div>
			)}
		</BaseContainer>
	);
});

export default AdminHomeContainer;
