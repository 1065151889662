import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { MainMenu } from './MainMenu';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useStore } from 'app/context';
import { useEffect } from 'react';
import { AdminImportJobsList } from 'app/components/admin/imports/AdminImportJobsList';
import { AdminImportJobDetailPanel } from 'app/components/admin/imports/AdminImportJobDetail';
import { ImportDataType } from 'app/stores/admin.imports.store';
import { EnsureAdmin } from './AdminHomeContainer';
import { AdminImportNewButton } from 'app/components/admin/imports/AdminImportNewButton';
import moment from 'moment';

export interface AdminUsersParamTypes {
	type?: string;
	id?: string;
}

const AdminActions = () => {
	return (
		<>
			<div className="admin-actions">
				<EnsureAdmin />
				<AdminImportNewButton path="aup" title="AUP" />
				<AdminImportNewButton path="aupvorjahr" title="AUP Vorjahr" />
				<AdminImportNewButton path="aladin" title="ALADIN 1" />
				<AdminImportNewButton path="aladin2" title="ALADIN 2" />
				<AdminImportNewButton path="leadnow" title="LEADNOW" />
				<AdminImportNewButton path="crawler" title="CRAWLER" />

				<div className="divider"></div>
				<AdminImportNewButton path="agenturcluster" title="Agentur Cluster" />

				<div className="divider"></div>

				<AdminImportNewButton path="akumaprime" title="AKUMA PRIME" />
				<AdminImportNewButton path="akumaplaner" title="AKUMA PLANER" />
				<AdminImportNewButton path="digitalreport" title="Digital Report" />
				<div className="divider"></div>

				<AdminImportNewButton path="multisagentur" title="Multis:Agenturen" />
				<AdminImportNewButton path="multisgp" title="Multis:GP" />

				<AdminImportNewButton path="googleprofile" title="GoogleProfile" />
				<AdminImportNewButton path="amisnow" title="AMIS.NOW" />

			</div>


		</>
	);
};

const AdminImportsContainer = observer((props: RouteComponentProps) => {
	const { session, adminImportsUiStore } = useStore();

	const currentUser = session.currentUser;

	const { type, id } = useParams<AdminUsersParamTypes>();

	useEffect(() => {
		if (type === 'job') {
			adminImportsUiStore.loadById(id);
		}
		if (type === 'new') {
			if (id === ImportDataType.aupvorjahr) {
				adminImportsUiStore.newAupVorjahrJob();
			}
			if (id === ImportDataType.aup) {
				adminImportsUiStore.newAupJob();
			}
			if (id === ImportDataType.crawler) {
				adminImportsUiStore.newCrawlerJob();
			}
			if (id === ImportDataType.aladin) {
				adminImportsUiStore.newAladinJob();
			}
			if (id === ImportDataType.aladin2) {
				adminImportsUiStore.newAladin2Job();
			}
			if (id === ImportDataType.amisnow) {
				adminImportsUiStore.adminsNowJob();
			}

			if (id === ImportDataType.leadnow) {
				adminImportsUiStore.newLeadnowJob();
			}

			if (id === ImportDataType.akumaprime) {
				adminImportsUiStore.newAkumaPrime();
			}

			if (id === ImportDataType.akumaplaner) {
				adminImportsUiStore.newAkumaPlaner();
			}

			if (id === ImportDataType.digitalreport) {
				adminImportsUiStore.newDigitalreport();
			}
			if (id === ImportDataType.agenturcluster) {
				adminImportsUiStore.newAgenturClusterJob();
			}

			if (id === ImportDataType.multisagentur) {
				adminImportsUiStore.newAgenturMultisAgenturJob();
			}
			if (id === ImportDataType.multisgp) {
				adminImportsUiStore.newAgenturMultisGPJob();
			}

			if (id === ImportDataType.googleprofile) {
				adminImportsUiStore.newGoogleProfileJob();
			}
		}
	}, [adminImportsUiStore, id, type]);

	const ImportNavbar = () => {
		return (
			<>
				<div className="admin-navbar">
					<div className="is-left">
						<strong>Daten Importieren</strong>
					</div>
					<div className="is-right">
						<strong>KW {moment().format("WW")}</strong>
					</div>
				</div>
			</>
		);
	};

	return (
		<BaseContainer classModifier="admin-container" sideMenu={<MainMenu {...props} />} navbar={<ImportNavbar />}>
			<AdminActions />
			{currentUser && (
				<>
					<AdminImportJobsList />
					<AdminImportJobDetailPanel />
				</>
			)}
		</BaseContainer>
	);
});

export default AdminImportsContainer;
