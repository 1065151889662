import _ from "lodash";


export const aggregateAvg = (data: any[], path: string, aggIf: (val: any) => boolean) => {
    let sum = 0;
    let count = 0;
    data.forEach((d) => {
        const prop = path! as string;
        const val = _.get(d, prop);
        if (aggIf(val)) {
            count++;
            sum += val;
        }
    });
    // return Math.round(sum / count);
    return Math.round((sum * 100) / count) / 100;

}

export const aggregateSum = (data: any[], path: string, aggIf: (val: any) => boolean) => {
    let sum = 0;
    data.forEach((d) => {
        const prop = path
        const val = _.get(d, prop);
        if (aggIf(val)) {
            sum += val;
        }
    });
    return sum;

}

export const aggregateCount = (data: any[], path: string, aggIf: (val: any) => boolean) => {
    let count = 0;
    data.forEach((d) => {
        const prop = path;
        const val = _.get(d, prop);
        if (aggIf(val)) {
            count++;
        }
    });
    return count;

}

export const aggregatePercentCountOfTotal = (data: any[], path: string, aggIf: (val: any) => boolean) => {
    let count = 0;
    let countTotal = 0;
    data.forEach((d) => {
        const prop = path;
        const val = _.get(d, prop);
        countTotal++;
        if (aggIf(val)) {
            count++;
        }
    });
    // return Math.round(sum / count);
    return Math.round((count * 100) / countTotal);

}