import { useStore } from 'app/context';
import { AupFile } from 'app/stores/admin.imports.store';
import { ImportJobVM } from 'app/stores/ui/admin.imports.ui.store';
import routes from 'routes';
import { notify } from '../common/notify';
import { useHistory } from 'react-router';
import { formatDateLong, humanizeFileSize } from 'app/utils';
import { Button } from '../common/Button';
import { observer } from 'mobx-react';
import { ITableColumn, TableModel, TableRow } from '../table/table.model';
import { Table } from '../table/table';
import { useEffect, useState } from 'react';
import { Modal } from '../Modal';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import FormField from '../form/FormField';
import { DatePickerField } from '../form/DatePickerField';
import { DatenStandList } from '../DatenStandList';
import { DatenStandModel } from 'app/stores/datenstand.store';


const DateStandSchema = Yup.object({
	stand: Yup.date().required('Datum ist erfoderlich').default(new Date()),
}).required();

type DatenStandForm = Yup.InferType<typeof DateStandSchema>;



interface IDatenStandEntryModal {
	job: ImportJobVM;
	currentDatenStand?: DatenStandModel
}

export const DatenStandEntryModal = observer((props: IDatenStandEntryModal) => {
	const { uiStore, adminImportsUiStore, adminDatenStandStore: datenStandStore } = useStore();
	const history = useHistory();

	useEffect(() => {
		datenStandStore.findAll().then(res => {
			const datenStand = res.find(x => x.dataType === props.job.job.dataType);
			if (datenStand && datenStand.stand) {
				setInitialDs(datenStand.stand)
			}
		})
	}, []);


	const [initialDs, setInitialDs] = useState<Date>(new Date());
	const onClose = () => {

		uiStore.hideModal(uiStore.modalIds.adminDatenstand);

	};

	const handleSubmit = async (values: DatenStandForm) => {
		const j = props.job.job;
		if (!j.meta) {
			j.meta = {};
		}
		j.meta.datenStand = values.stand;
		notify('Job wird gestartet', '', 'info');
		adminImportsUiStore.triggerJob(props.job.job).then((res: any) => {
			history.push(routes.ADMINIMPORTS.getPath('job', res.id));
			onClose();
		});
		// uiStore.showModal(uiStore.modalIds.adminDatenstand);

	};


	return (
		<Modal modalId={uiStore.modalIds.adminDatenstand} title={'Datenstand'} onClose={onClose} size='large'>
			<Formik
				validationSchema={DateStandSchema}
				initialValues={{
					stand: initialDs,
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, isValid, values }) => {

					return (
						<Form>
							<div className="pad-1rem">
								<FormField className="is-inline" error={errors.stand ? String(errors.stand) : undefined} touched={touched.stand ? true : false} label="Datestand">
									<DatePickerField name="stand" />
								</FormField>
								<Button type="submit" className={'button is-primary'} >
									Import Starten!
								</Button>
								<Button type="button" className={'button is-secondary'} onClick={onClose} >
									Abbrechen
								</Button>
								<br />
								<DatenStandList />
							</div>


						</Form>
					);
				}}
			</Formik>
		</Modal>
	);
});



interface IAdminImportFiles {
	files: AupFile[];
	job: ImportJobVM;
}




export const AdminImportFiles = observer((props: IAdminImportFiles) => {
	const { adminImportsUiStore, uiStore, adminDatenStandStore: datenStandStore } = useStore();
	const history = useHistory();

	const startImportJob = (fileName: string) => {

		if (!props.job.job.meta) {
			props.job.job.meta = {};
		}
		props.job.job.meta.fileName = fileName;
		uiStore.showModal(uiStore.modalIds.adminDatenstand);


	};

	const handleDelete = (fileName: string) => {
		if (!window.confirm(`Soll die Datei ${fileName} wirklich gelöscht werden?`)) {
			return;
		}

		notify('Datei wird gelöscht', '', 'info');
		fileName = props.job.job.dataType + '/' + fileName;
		adminImportsUiStore.adminImportsStore.deleteUploadFile(fileName).then(() => {
			window.location.reload();
		});
	};

	const tm = new TableModel<AupFile, number>();
	let cols: ITableColumn<AupFile, number>[] = [
		{
			label: 'Name',
			path: 'fileName',
		},
		{
			label: 'Datum',
			path: 'fileDate',
			format: (val) => formatDateLong(val),
		},
		{
			label: 'Größe',
			path: 'fileSize',
			format: (val) => humanizeFileSize(val),
		},
		{
			label: 'DatenStand',
			path: 'datenStand.standFormated',

		},
		{
			label: 'Import',
			path: 'fehler',
			render: (row: TableRow<AupFile>) =>
				<Button type="button" className={'button is-primary'} onClick={() => startImportJob(row.data.fileName)}>
					Importieren
				</Button>,
		},

		{
			label: 'Löschen',
			path: 'Delete',
			render: (row: TableRow<AupFile>) =>
				<Button type="button" className={'button is-danger'} onClick={() => handleDelete(row.data.fileName)}>
					Löschen
				</Button>,
		},
	];
	tm.setCols(cols);
	tm.sortBy = 'fileDate';
	tm.sortAsc = false;
	tm.idProperty = 'id';
	tm.idType = 'string';

	useEffect(() => {
		datenStandStore.findAll().then((staende) => {
			const datenStand = staende.find(x => x.dataType === props.job.job.dataType);

			props.files.forEach(f => {
				if (f.fileName === datenStand?.file) {
					f.datenStand = datenStand;
				}

			});
			tm.setRowData(props.files);
		})

	}, [props.files])

	return (
		<div className="pad-top-1rem border-top">
			<strong>Hochgeladene Dateien: </strong>
			<br />
			{props.files.length === 0 && <>Keine Dateien hochgeladen </>}
			<Table stickyHeader={true} tm={tm}></Table>
			<DatenStandEntryModal job={props.job} />

		</div>
	);
});
