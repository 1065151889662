import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';
import { Table } from 'app/components/table/table';
import { AggregrationType, ITableColumn, TableModel, TableRow } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { AgtAkumaPrimeKwVM, IAkumaPrimeDef, AkumaPrimeType } from 'app/stores/ui/agt.akumaprime.ui.store';
import { useHistory } from 'react-router';
import { formatNum, formatPercentTwoDig } from 'app/utils';
import { AgenturModel } from 'app/models/agentur.model';

interface IAkumaPrimeList {
	schema: IAkumaPrimeDef[];
	quoteProps: string;
	totalProps?: string;
	data: AgtAkumaPrimeKwVM[];
	primeType: AkumaPrimeType;
	selectedAgt?: AgenturModel;
	showKw: boolean;
}

export const AkumaPrimeListeAgt = observer((props: IAkumaPrimeList) => {
	const tm = useMemo(() => {
		const tm = new TableModel<any, number>();
		const cols: ITableColumn<any, number>[] = [];

		const pData = props.data[0].akumePrimeVals.filter((p) => p.type === props.primeType);

		// organise table to have the schema labels underneath each other
		cols.push({
			label: 'Label',
			path: 'label',
		});
		cols.push({
			label: 'Anzahl',
			path: 'value',
		});
		cols.push({
			label: '%',
			path: 'percent',
			format: formatPercentTwoDig,
		});

		tm.setCols(cols);
		tm.setRowData(pData);

		return tm;
	}, [props.data, props.primeType]);

	return (
		<>
			<Table stickyHeader={true} tm={tm}></Table>
		</>
	);
});

export const AkumaPrimeListe = observer((props: IAkumaPrimeList) => {
	const { berichteUiiStore, agtAkumaPrimeUiStore, agenturListUiStore } = useStore();

	const history = useHistory();
	const schmema = props.schema;

	const tm = useMemo(() => {
		const tm = new TableModel<AgtAkumaPrimeKwVM, number>();
		const cols: ITableColumn<AgtAkumaPrimeKwVM, number>[] = [];
		if (!props.selectedAgt) {
			cols.push({
				label: 'Agentur',
				path: 'agt.name',
				sortBy: 'agt.name',
				render: AgenturNameCell,
			});
		}
		if (props.showKw) {
			cols.push({
				label: 'KW',
				path: 'bm',
				sortBy: 'bm',
			});
		}
		if (props.primeType === AkumaPrimeType.einordnung && props.totalProps) {
			cols.push({
				label: 'Einzuordnende Kunden',
				path: props.totalProps,
				format: formatNum,
				agg: [
					{
						path: props.totalProps,
						aggType: AggregrationType.sum,
					},
				],
			});
		}

		schmema.forEach((c) => {
			cols.push({
				label: c.label,
				// path: `${c.type}.${c.key}`,
				path: `${c.path}`,
				format: formatNum,
				agg: [
					{
						path: `${c.type}.${c.key}`,
						aggType: AggregrationType.sum,
					},
				],
			});
		});

		if (props.primeType === AkumaPrimeType.einordnung) {
			cols.push({
				label: 'Quote',
				path: props.quoteProps,
				format: formatPercentTwoDig,
			});
		}

		tm.sortBy = 'agt.name';
		tm.setCols(cols);
		tm.onSort = () => {
			agtAkumaPrimeUiStore.chartKey = 'chart' + new Date().getTime();
			// setChartKey('chart' + new Date().getTime());
		};
		tm.onRowClick = (row: TableRow<AgtAkumaPrimeKwVM>) => {
			history.push({
				search: '?agtId=' + row.data.agtId,
			});
		};

		tm.idProperty = 'agtId';
		tm.idType = 'number';
		if (props.showKw) {
			tm.sortBy = 'bm';
			tm.sortAsc = false;
		}
		return tm;
	}, [props.selectedAgt, props.showKw, props.primeType, props.totalProps, props.quoteProps, schmema, agtAkumaPrimeUiStore, history]);

	useEffect(() => {
		let items = props.data;

		if (berichteUiiStore.selectedAgt) {
			const agtId = berichteUiiStore.selectedAgt.agtId;
			items = items.filter((d) => d.agtId === agtId);
		}

		tm.setRowData(items);
		tm.sort(); // important -> otherwise the charts labels will not match the rows.
	}, [props.data, tm, berichteUiiStore.selectedAgt, agenturListUiStore.filterHasChanged, agenturListUiStore]);

	if (tm.data.length === 0) {
		return <></>;
	}

	return (
		<>
			<Table stickyHeader={true} tm={tm}></Table>
		</>
	);
});
