import { useStore } from "app/context"
import { DatenStandModel } from "app/stores/datenstand.store";
import { observer } from "mobx-react"
import { useEffect } from "react";
import { ITableColumn, TableModel } from "./table/table.model";
import { Table } from "./table/table";
import { DottedTag } from "./common/DottedTag";

export const DatenStandList = observer(() => {

    const { adminDatenStandStore: datenStandStore } = useStore();
    const tm = new TableModel<DatenStandModel, string>();
    const cols: ITableColumn<DatenStandModel, string>[] = [
        {
            label: 'Quelle',
            path: 'dataType',
        },
        {
            label: 'Turnus',
            path: 'cycle',
        },
        {
            label: 'Datenstand',
            path: 'standFormated',
        },

        {
            label: 'Anzahl Agts',
            path: 'agtCount',
        },
        {
            label: 'Anzahl Zeilen',
            path: 'rowCount',
        },
        {
            label: 'Alter (Tage)',
            path: 'ageInDays',
            render: (row) => {
                return <DottedTag color={row.data.color} text={row.data.ageInDays.toString()} />
            }
        },
    ];

    tm.setCols(cols);
    tm.sortBy = 'steart.sortOrder';
    tm.idProperty = 'bnrId';
    tm.idType = 'number';

    useEffect(() => {
        datenStandStore.findAll().then(res => {
            tm.setRowData(res)

        });

    }, [datenStandStore])

    return <>
        <Table tm={tm} />

    </>
})

