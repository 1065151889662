import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Icon } from 'app/components/common/Icon';
import { observer } from 'mobx-react';
import { AggregrationType, TableAggregrateColumn, TableColumn, TableModel, TableRow } from './table.model';
import { Field, Form, Formik, useFormikContext } from 'formik';
import Yup from 'yup';
import { Button } from '../common/Button';
import { autorun, runInAction } from 'mobx';
import { SegmentSelectField } from '../form/SegmentSelectField';
import classNames from 'classnames';
import { SegmentState } from 'app/models/segment/segment.model';
import { CSVLink } from 'react-csv';
import VisibleObserver from '../common/VisibleObserver';

interface ITable<T, P> {
	tm: TableModel<T, P>;
	onSubmit?: () => void;
	onCancel?: () => void;
	schema?: Yup.AnySchema;
	stickyHeader?: boolean;
	stickyFooter?: boolean;
	legend?: JSX.Element;
	modifier?: string;
	allowCsvExport?: boolean;
	csvExportName?: string;
}

interface ITableCell<T, P> {
	row: TableRow<T>;
	column: TableColumn<T, P>;
	tm: TableModel<T, P>;
	index: number;
}

interface IExtraInput<T, P> {
	basePath: string;
	path: string;
	targetThing: any;
	tm: TableModel<T, P>;
}

interface IFieldValidation {
	fieldPath: string;
}
const FieldValidation = (props: IFieldValidation) => {
	const { errors, touched } = useFormikContext<IFormData>();
	const t = _.get(touched, props.fieldPath);
	const e = _.get(errors, props.fieldPath);
	if (e && t) {
		return e;
	}
	return '';
};

export const SegmentDropdown = observer((props: IExtraInput<any, any>) => {
	const fieldPath = `${props.basePath}.${props.path}`;
	const { handleChange } = useFormikContext<IFormData>();
	const onChange = (e: any) => {
		runInAction(() => {
			props.targetThing[props.path] = e.target.value;
			handleChange(e);
		});
	};

	const isDisabled = props.targetThing.calcSegement === SegmentState.starter;

	return (
		<>
			<SegmentSelectField disabled={isDisabled} name={fieldPath} type="checkbox" onChange={onChange} />
			<FieldValidation fieldPath={fieldPath} />
		</>
	);
});

export const Checkbox = observer((props: IExtraInput<any, any>) => {
	const fieldPath = `${props.basePath}.${props.path}`;
	const { handleChange } = useFormikContext<IFormData>();
	const onChange = (e: any) => {
		runInAction(() => {
			props.targetThing[props.path] = e.target.checked;
			handleChange(e);
		});
	};
	return (
		<>
			<Field name={fieldPath} type="checkbox" onChange={onChange} />
			<FieldValidation fieldPath={fieldPath} />
		</>
	);
});

export const NumberInput = observer((props: IExtraInput<any, any>) => {
	const fieldPath = `${props.basePath}.${props.path}`;
	const { getFieldProps, getFieldMeta, handleBlur, setFieldValue } = useFormikContext<IFormData>();
	const [value, setValue] = useState(getFieldMeta(fieldPath).value);
	useEffect(() => {
		//update field, if changed from outside the form
		return autorun(() => {
			const v = _.get(props.targetThing, props.path);
			setValue(v);
		});
	}, [props.targetThing, props.path]);

	const onChange = (e: any) => {
		let v = e.target.value;
		v = v === null || Number.isNaN(v) ? 0 : v;

		setValue(v);
		setFieldValue(fieldPath, v);
	};
	const onBlur = (e: any) => {
		handleBlur(e);
		let v = getFieldProps(fieldPath).value;
		v = v === null || Number.isNaN(v) ? 0 : v;
		setFieldValue(fieldPath, v);
		const err = getFieldMeta(fieldPath).error;
		if (!err) {
			if (Array.isArray(props.path)) {
				console.warn('Multi line edit not supported');
			} else {
				runInAction(() => {
					_.set(props.targetThing, props.path, parseFloat(v));
				});
			}
		}
	};
	return (
		<>
			<Field name={fieldPath} type="text" onBlur={onBlur} value={value === undefined || Number.isNaN(value) ? '' : value} onChange={onChange} />
			<FieldValidation fieldPath={fieldPath} />
		</>
	);
});

export interface IFormData {
	data: any[];
}

const TableCell = observer((props: ITableCell<any, any>) => {
	const enableEdit = props.column.editable && props.tm.editMode;
	if (props.column.render && !enableEdit) {
		return props.column.render(props.row);
	}

	if (enableEdit) {
		const index = props.index;
		const basePath = `data.${index}`;
		const path = props.column.path;
		if (Array.isArray(path)) {
			console.warn('Multi line edit not supported ATM');
			return <>Multi line edit not supported ATM</>;
		}
		if (props.column.editType === 'checkbox') {
			return (
				<>
					<Checkbox {...props} basePath={basePath} path={path} targetThing={props.row.data} />
				</>
			);
		}
		if (props.column.editType === 'segment') {
			return (
				<>
					<SegmentDropdown {...props} basePath={basePath} path={path} targetThing={props.row.data} />
				</>
			);
		}
		return (
			<>
				<NumberInput {...props} basePath={basePath} path={path} targetThing={props.row.data} />
			</>
		);
	}
	if (Array.isArray(props.column.path)) {
		return (
			<>
				{props.column.path.map((path, i) => {
					const k = props.column.keyPath + i;
					let value = _.get(props.row.data, path);
					if (props.column.format) {
						value = props.column.doFormat(value, i);
					}
					return <div key={k}>{value}</div>;
				})}
			</>
		);
	} else {
		let value = _.get(props.row.data, props.column.path);
		if (props.column.format) {
			value = props.column.doFormat(value, 0);
		}
		return <>{value}</>;
	}
});

interface ITableHeader<T, P> {
	tm: TableModel<T, P>;
	column: TableColumn<T, P>;
}

const TableHeaderLabel = observer((props: ITableHeader<any, any>) => {
	return (
		<>
			{/* <div className="sort-icon">{props.column.icon && <Icon iconClass={props.column.icon} />}</div> */}
			<div className="sort-label ">
				{Array.isArray(props.column.label) ? (
					<>
						{props.column.label.map((label, i) => (
							<div key={i}>{label}</div>
						))}
					</>
				) : (
					<span>{props.column.label}</span>
				)}
			</div>
		</>
	);
});

interface ITableHeaderCell extends ITableHeader<any, any> {
	width?: number;
}
const TableHeaderCell = observer((props: ITableHeaderCell) => {
	const sort = () => {
		if (props.tm.sortDisabled) {
			return;
		}
		let sortProp = props.column.sortBy ? props.column.sortBy : props.column.path;
		if (Array.isArray(sortProp)) {
			sortProp = sortProp[0];
		}
		props.tm.setSortBy(sortProp);
		props.tm.sort();
	};

	const isSorting = props.column.path === props.tm.sortBy;
	const sortClass = isSorting ? (props.tm.sortAsc ? 'is-sorting sort-asc' : 'is-sorting sort-desc') : '';

	const minWidth = props.column.width ? `${props.column.width}px` : '';
	const width = props.width ? `${props.width}px` : '';
	const labelModifier = props.column.labelModifier || '';

	return (
		<th className={`${sortClass} ${labelModifier}`} style={{ minWidth, width }}>
			<Button className="table-sort-button" onClick={sort}>
				<TableHeaderLabel {...props} />

				{isSorting && (
					<span className="sort-icon">
						<Icon iconClass={props.tm.sortAsc ? 'caret-up' : 'caret-down'} />
					</span>
				)}
			</Button>
		</th>
	);
});

export const Table = observer((props: ITable<any, any>) => {
	if (props.tm.hasEditbableColumns) {
		const handleSubmit = () => {
			if (props.onSubmit) {
				props.onSubmit();
			}
		};
		return (
			<>
				<Formik onSubmit={handleSubmit} initialValues={props.tm.formData} enableReinitialize validateOnMount={true} validationSchema={props.schema}>
					{({ values, errors, touched }) => (
						<Form className="form is-table-form table-container">
							<TableCore {...props} />
						</Form>
					)}
				</Formik>
			</>
		);
	}
	return (
		<>
			<TableCore {...props} />
		</>
	);
});

interface ITableRow<T, P> {
	tm: TableModel<T, P>;
	row: TableRow<T>;
	index: number;
}
export const TableRowOrGroup = observer((props: ITableRow<any, any>) => {
	const { row, tm } = props;
	const i = props.index;
	const id = row.data[tm.idProperty];

	const index = tm.data.findIndex((d) => d[tm.idProperty] === id);
	const selectClass = row.selected ? 'is-active' : '';
	const hoverClass = row.hovering ? 'is-hovered' : '';

	const handleRowKeypress = (e: React.KeyboardEvent<HTMLTableRowElement>, row: TableRow<any>) => {
		if (e.key === 'Enter') {
			tm.onRowClick(row);
		}
	};

	const handleRowClick = (e: React.MouseEvent, row: TableRow<any>) => {
		if (e.shiftKey || e.metaKey) {
			tm.onRowShiftClick(row);
			return;
		}

		tm.onRowClick(row);
	};

	return (
		<>
			{row.isGroupStart && (
				<tr className="is-group-header">
					<td>
						<strong>{row.groupLabel}</strong>
					</td>
					<td colSpan={tm.columnCount - 1}></td>
				</tr>
			)}
			<tr
				tabIndex={tm.editMode ? -1 : 0}
				className={selectClass + ' ' + hoverClass}
				onClick={(e) => handleRowClick(e, row)}
				onMouseEnter={() => tm.setHoverId(id)}
				onMouseLeave={() => tm.setHoverId()}
				onKeyPress={(e) => handleRowKeypress(e, row)}
			>
				{tm._showIndx && <td>{index}</td>}
				{tm.columns.map((col, j) => (
					<td key={'cell_' + i.toString() + '_' + j.toString()} className={col.cellModifier || ''}>
						<TableCell index={index} row={row} column={col} tm={tm} />
					</td>
				))}
			</tr>
		</>
	);
});

export const TableCore = observer((props: ITable<any, any>) => {
	const tm = props.tm;
	const stickyHeader = props.stickyHeader;
	const stickyFooter = props.stickyFooter;
	const modifier = props.modifier || '';

	const classes = classNames(
		'table',
		modifier,
		{ 'has-sticky-header': stickyHeader },
		{ 'has-sticky-footer': stickyFooter },
		{ 'is-editable': tm.hasEditbableColumns },
		{ 'edit-active': tm.editMode },
	);

	return (
		<>
			<div className={`table-container `}>
				{tm.hasEditbableColumns && <TableEditHeader {...props} />}
				<table className={classes}>
					{props.allowCsvExport && (
						<caption>
							<div className="is-left">
								<TableCSVExport {...props} />
							</div>
						</caption>
					)}
					{tm.showHeader && (
						<thead>
							<tr>
								{tm._showIndx && <th>index</th>}

								{tm.columns.map((col, i) => (
									<TableHeaderCell key={'head' + i} column={col} tm={tm} />
								))}
							</tr>
						</thead>
					)}
					<tbody>
						{/* <tr><td colSpan={tm.columns.length} >
							batchedRows.length: {tm.batchedRows.length}<br />
							tm.firstBatch.length {tm.firstBatch.length} <br />
							tm.visibleRowCount {tm.visibleRowCount} <br />
							tm.rows.length {tm.rows.length} <br />

						</td></tr> */}
						{tm.firstBatch.map((row, i) => (
							<TableRowOrGroup key={'row_' + i.toString()} tm={tm} row={row} index={i} />
						))}
						{tm.otherBatches.map((batch, i) => batch.map((row, j) => <TableRowOrGroup key={'row_' + j.toString()} tm={tm} row={row} index={j} />))}
						{!tm.showingAllRows && (
							<>
								<VisibleObserver onVisible={() => tm.showMoreRows()}>
									<Button className="button is-primary is-inverted" onClick={() => tm.showMoreRows()}>
										{tm.renderBatchSize} Mehr anzeigen
									</Button>
								</VisibleObserver>
							</>
						)}
					</tbody>
					{tm.showFooter && (
						<tfoot>
							{tm.showSum && (
								<tr>
									{tm._showIndx && <th>index</th>}
									{tm.columns.map((col, i) => (
										<TableFooterCell key={'foot_' + i} column={col} tm={tm} />
									))}
								</tr>
							)}

							{tm.hasEditbableColumns && <TableEditFooter {...props} />}
						</tfoot>
					)}
				</table>
				{props.legend && <div className="table-legend">{props.legend}</div>}
			</div>
		</>
	);
});

const TableCSVExport = observer((props: ITable<any, any>) => {
	const { tm, csvExportName } = props;

	const labels = tm.columns.map((col) => col.label);
	const rows = tm.rows.map((row) => {
		const r = tm.columns.map((col) => {
			return _.get(row.data, col.path);
		});
		return r;
	});

	return (
		<CSVLink data={[labels, ...rows]} filename={csvExportName || 'export.csv'} separator={';'} enclosingCharacter={''} className="csv-download-link">
			Download CSV
		</CSVLink>
	);
});

const TableEditHeader = observer((props: ITable<any, any>) => {
	const tm = props.tm;
	const { errors } = useFormikContext<IFormData>();
	const hasErrors = errors && errors.data && errors.data.length > 0;
	const toggleEditMode = () => {
		tm.setEditMode(true);
	};
	const cancel = () => {
		if (props.onCancel) {
			props.onCancel();
		}
		tm.setEditMode(false);
	};
	const save = async () => {
		if (props.onSubmit) {
			props.onSubmit();
		}
		tm.setEditMode(false);
	};

	return (
		<div className="table-edit-header">
			{tm.editMode ? (
				<>
					<Button type="button" className="button is-primary " onClick={save} disabled={!!hasErrors}>
						Speichern
					</Button>
					<Button type="button" className="button is-secondary " onClick={cancel}>
						Abbrechen
					</Button>
				</>
			) : (
				<>
					<Button type="button" className="button is-primary" onClick={toggleEditMode}>
						Bearbeiten
					</Button>
				</>
			)}
		</div>
	);
});

const TableEditFooter = observer((props: ITable<any, any>) => {
	const tm = props.tm;
	const { errors } = useFormikContext<IFormData>();
	const hasErrors = errors && errors.data && errors.data.length > 0;
	const toggleEditMode = () => {
		tm.setEditMode(true);
	};
	const cancel = () => {
		if (props.onCancel) {
			props.onCancel();
		}
		tm.setEditMode(false);
	};
	const save = async () => {
		if (props.onSubmit) {
			props.onSubmit();
		}
		tm.setEditMode(false);
	};

	return (
		<tr className="table-edit-footer">
			<td colSpan={tm.columnCount}>
				{tm.editMode ? (
					<>
						<Button type="button" className="button is-primary " onClick={save} disabled={!!hasErrors}>
							Speichern
						</Button>
						<Button type="button" className="button is-secondary " onClick={cancel}>
							Abbrechen
						</Button>
					</>
				) : (
					<>
						<Button type="button" className="button is-primary" onClick={toggleEditMode}>
							Bearbeiten
						</Button>
					</>
				)}
			</td>
		</tr>
	);
});

const TableFooterCell = observer((props: ITableHeader<any, any>) => {
	if (!props.column.aggs) {
		return <td></td>;
	}

	return (
		<td>
			<div className="aggregations">
				{props.column.aggs.map((agg, i) => (
					<TableFooterAgg key={i.toString()} agg={agg} />
				))}
			</div>
		</td>
	);
});

interface ITableFooterAgg {
	agg: TableAggregrateColumn<any, any>;
}
const TableFooterAgg = observer((props: ITableFooterAgg) => {
	const agg = props.agg;
	if (agg.visible === false) {
		return <></>;
	}
	if (agg.render) {
		return agg.render(agg.tm, agg.aggValue);
	}

	let symbol = (
		<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M0.928828 12.6756L0.649994 13.1068L1.13238 14H9.69816C10.2158 14 10.7123 13.7935 11.0783 13.4259C11.4444 13.0584 11.65 12.5598 11.65 12.04V11.2H9.97699V12.04C9.97699 12.1143 9.94761 12.1855 9.89532 12.238C9.84303 12.2905 9.77211 12.32 9.69816 12.32H3.19575L6.82059 7L3.19575 1.68L9.69816 1.68C9.77211 1.68 9.84303 1.7095 9.89532 1.76201C9.94761 1.81452 9.97699 1.88574 9.97699 1.96V2.8H11.65V1.96C11.65 1.44018 11.4444 0.941642 11.0783 0.574071C10.7123 0.2065 10.2158 0 9.69816 0H1.63428H1.12401L0.649994 0.9044L4.78231 7L0.928828 12.6756Z"
				fill="black"
			/>
		</svg>
	);
	let aggTitle = 'Summe';

	if (agg.aggType === AggregrationType.avg) {
		symbol = (
			<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M10.4632 2.38537L11.2614 1.01403L10.1971 0.399994L9.4193 1.77134C8.64152 1.42339 7.78187 1.21871 6.88128 1.21871C3.48362 1.21871 0.699997 3.98187 0.699997 7.39999C0.699997 9.46725 1.72339 11.2889 3.27894 12.4146L2.4807 13.786L3.54503 14.4L4.3228 13.0287C5.10058 13.3766 5.96023 13.5813 6.86082 13.5813C10.2585 13.5813 13.0421 10.8181 13.0421 7.39999C13.0421 5.33274 12.0187 3.51111 10.4632 2.38537ZM1.9076 7.39999C1.9076 4.67777 4.11813 2.44678 6.86082 2.44678C7.53626 2.44678 8.19123 2.59005 8.78479 2.83567L3.87251 11.3298C2.68538 10.4292 1.9076 9.01695 1.9076 7.39999ZM6.86082 12.3532C6.18538 12.3532 5.53041 12.2099 4.93684 11.9643L9.84912 3.47017C11.0363 4.37075 11.814 5.8035 11.814 7.42046C11.814 10.1222 9.58304 12.3532 6.86082 12.3532Z"
					fill="black"
				/>
			</svg>
		);
		aggTitle = 'Durchschnitt';
	}
	if (agg.aggType === AggregrationType.count) {
		symbol = (
			<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M14.0302 0.0352478H0.372663C0.17626 0.0352478 0.0251808 0.186327 0.0251808 0.382729V2.01438C0.0251808 2.21078 0.17626 2.36186 0.372663 2.36186H14.0151C14.2115 2.36186 14.3626 2.21078 14.3626 2.01438V0.382729C14.3777 0.186327 14.2266 0.0352478 14.0302 0.0352478Z"
					fill="black"
				/>
				<path
					d="M14.0302 3.90288H0.372663C0.17626 3.90288 0.0251808 4.05396 0.0251808 4.25036V5.88201C0.0251808 6.07842 0.17626 6.2295 0.372663 6.2295H14.0151C14.2115 6.2295 14.3626 6.07842 14.3626 5.88201V4.25036C14.3777 4.05396 14.2266 3.90288 14.0302 3.90288Z"
					fill="black"
				/>
				<path
					d="M14.0302 7.77049H0.372663C0.17626 7.77049 0.0251808 7.92157 0.0251808 8.11797V9.74962C0.0251808 9.94602 0.17626 10.0971 0.372663 10.0971H14.0151C14.2115 10.0971 14.3626 9.94602 14.3626 9.74962V8.11797C14.3777 7.92157 14.2266 7.77049 14.0302 7.77049Z"
					fill="black"
				/>
				<path
					d="M14.0302 11.6381H0.372663C0.17626 11.6381 0.0251808 11.7892 0.0251808 11.9856V13.6173C0.0251808 13.8137 0.17626 13.9647 0.372663 13.9647H14.0151C14.2115 13.9647 14.3626 13.8137 14.3626 13.6173V11.9856C14.3777 11.7892 14.2266 11.6381 14.0302 11.6381Z"
					fill="black"
				/>
			</svg>
		);
		aggTitle = 'Anzahl';
	}

	if (agg.aggType === AggregrationType.empty || agg.aggType === AggregrationType.percentCountOfTotal) {
		symbol = <></>;
		aggTitle = '';
	}

	return (
		<div className={`aggregation-value ${agg.aggModifier || ''}`} title={aggTitle}>
			<span className="agg-icon">{agg.aggCustomSymbol ? agg.aggCustomSymbol : symbol}</span>
			<span className="agg-value">
				{agg.doFormat(agg.aggValue)}
				&nbsp;
				{agg.aggInfo && <span className="agg-info">{agg.aggInfo}</span>}
			</span>
		</div>
	);
});

interface ITableAggInfo {
	iconClass?: string;
	infoText: string;
}

export const TableAggInfo = observer((props: ITableAggInfo) => {
	const { iconClass, infoText } = props;
	return (
		<>
			<Button className="button is-blank" data-tooltip={infoText} data-tooltip-position="top">
				<Icon iconClass={iconClass || 'info-circle'} faModifier="fal" />
			</Button>
		</>
	);
});
