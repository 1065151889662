import { MenuModel } from 'app/models/core/menu.model';
import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';
import { SessionStore } from './session.store';

const ModalIds = Object.freeze({
	expertiseEdit: 'expertise_edit',
	coronaLiquiFaden: 'coronaLiquiFaden',
	mitarbeiterEdit: 'editMitarbeiter',
	mitarbeiterView: 'viewMitarbeiter',
	confirmModal: 'confirmModal',
	confirmDeleteDocument: 'confirmDeleteDocument',
	confirmDeleteUpload: 'confirmDeleteUpload',
	confirmDeleteTemplate: 'confirmDeleteTemplate',
	confirmDeleteTask: 'confirmDeleteTask',
	documentSendModal: 'documentSendModal',
	documentShareModal: 'documentShareModal',
	documentViewModal: 'documentViewModal',
	notizViewModal: 'notizViewModal',
	taskViewModal: 'taskViewModal',
	templateSelect: 'templateSelect',
	templateEdit: 'templateEdit',
	agtSelect: 'agtSelect',
	bnrSelect: 'bnrSelect',
	apEdit: 'apEdit',
	apEditJahresZiel: 'apEditJahresZiel',
	apEditBestand: 'apEditBestand',
	apEditPlanungszeitraum: 'apEditPlanungszeitraum',
	apEditVermittler: 'apEditVermittler',
	apThemenAuswahl: 'apThemenAuswahl',
	apThemaDeleteCustom: 'apThemaDeleteCustom',
	apExport: 'apExport',
	apExportSendModal: 'apExportSendModal',
	taskSendModal: 'taskSendModal',
	changePassword: 'changePassword',
	bnrErrungenschaften: 'bnrErrungenschaften',
	addEigenesThema: 'addEigenesThema',
	newTempAgentur: 'newTempAgentur',
	editTempAgentur: 'editTempAgentur',
	adminNewUser: 'adminNewUser',
	adminNewMulti: 'adminNewMulti',
	adminUpdateOrga: 'adminUpdateOrga',
	adminNewBnrTransfer: 'adminNewBnrTransfer',
	adminConfirmLiveNofications: 'adminConfirmLiveNofications',
	errungenschaftMiniModal: 'errungenschaftMiniModal',
	agenturFilterModal: 'agenturFilterModal',
	updateBnrId: 'updateBnrId',
	pdfViewer: 'pdfViewer',
	tempAgtSelectTransferAgt: 'tempAgtSelectTransferAgt',
	gpDoks: 'gpDoks',
	adminNewNews: 'adminNewNews',
	akumaLegend: 'akumaLegend',
	adminDatenstand: 'adminDatenstand',
});

export class EditMode {
	constructor() {
		makeObservable(this);
	}

	@observable
	enabled: boolean = false;

	@action
	toggle() {
		this.enabled = !this.enabled;
	}
	@action
	startEdit() {
		this.enabled = true;
	}

	@action
	endEdit() {
		this.enabled = false;
	}
}

export class UiStore {
	constructor(session: SessionStore) {
		makeObservable(this);
		this.session = session;
		this.visibleModals = observable([]);
	}

	session: SessionStore;

	/// MODAL HANDLING
	@action
	hideModal(id: string) {
		this.visibleModals.replace(this.visibleModals.filter((x) => x !== id));
	}
	@action
	showModal(id: string) {
		if (!this.visibleModals.includes(id)) {
			this.visibleModals.push(id);
		} else {
			console.warn(`Modal ${id} is allready open`);
		}
	}

	@observable
	mainMenu: MenuModel = new MenuModel([]);

	@computed
	get visibleMenuItems() {
		if (!this.session.currentUser) {
			return [];
		}
		return this.mainMenu.items;
	}

	@observable
	visibleModals: IObservableArray<string>;

	modalIds = ModalIds;

	// Edit Modes

	@observable
	schwerpunktEdit = new EditMode();

	@observable
	branchenPlanungEdit = new EditMode();

	@observable
	apZieleEdit = new EditMode();

	@observable
	apThemenEdit = new EditMode();

	@observable
	apTaskNew = new EditMode();

	// UPLOADING State
	@observable
	isUploading: boolean = false;

	@action
	setIsUploading(s: boolean) {
		this.isUploading = s;
	}

	@observable
	fullScreenPanel: boolean = false;

	@action
	toggleFullScreenPanel() {
		this.fullScreenPanel = !this.fullScreenPanel;
	}

	@observable
	showMap: boolean = false;

	@action
	toggleMap() {
		this.showMap = !this.showMap;
	}

	@observable
	showMobileMenu: boolean = false;

	@action
	toggleMobileMenu(isOpen?: boolean) {
		if (typeof isOpen === 'boolean') {
			this.showMobileMenu = isOpen;
		} else {
			this.showMobileMenu = !this.showMobileMenu;
		}
	}

	@observable
	showPdf: string = '';

	@action
	setShowPdf(s: string) {
		this.showPdf = s;
		this.showModal(this.modalIds.pdfViewer);
	}

	@action
	closePdf() {
		this.showPdf = '';
		this.hideModal(this.modalIds.pdfViewer);
	}
}
